import React, { useState, useEffect } from "react";
import { Panel, PanelHeader, Footer, Spacing, Link, Placeholder, CellButton, SimpleCell, Group, Switch } from '@vkontakte/vkui';
import { useRouteNavigator, useParams } from '@vkontakte/vk-mini-apps-router';
import { Icon56SquareStackUpOutline, Icon28DoorArrowRightOutline } from '@vkontakte/icons';

export function App(props) {

const routeNavigator = useRouteNavigator();

useEffect(() => {
	Init();
}, []);

function Init() {

};

return (
	<Panel id={props.id}>
		<PanelHeader>Профиль</PanelHeader>
		<Group>
			<SimpleCell Component="label" after={<Switch onClick={() => props.setTheme(props.theme == 'dark' ? 'light' : 'dark')} defaultChecked={props.theme === 'dark' ? true : false} />}>Тёмная тема</SimpleCell>
			<CellButton mode="danger" onClick={() => {props.logout()}} before={<Icon28DoorArrowRightOutline />}>Выйти</CellButton>
		</Group>
    	<Placeholder stretched icon={<Icon56SquareStackUpOutline />} header="Упс, это ещё не готово">Всё впереди, скоро тут будут кнопочки и картинки :)</Placeholder>
		{props.Footer}
	</Panel>
)};