import React, { useState, useEffect  } from 'react';
import { AdaptivityProvider, View, FormStatus, Button, Link, FormItem, Input, SplitLayout, Spacing, RichCell, Avatar, Separator, Footer, SplitCol, Subhead, PanelHeaderButton, Title, Checkbox, Group, ButtonGroup, PanelHeader, Panel, CustomSelect   } from '@vkontakte/vkui';
import { Icon20SunOutline, Icon24BrowserBack, Icon28UsersCircleFillYellow, Icon20MessagesOutline, Icon28PincodeOutline, Icon24PhoneOutline} from '@vkontakte/icons';

import axios from 'axios';
import moment from 'moment';
import Countdown from "react-countdown";
import Fingerprint from 'fingerprintjs';
import sha256 from "crypto-js/sha256";

const login = (props) => {

const [phone, setPhone] = useState('');
const [code, setCode] = useState('');
const [key, setKey] = useState('');
const [name, setName] = useState('');
const [surname, setSurname] = useState('');
const [phoneIsWhatsApp, setPhoneIsWhatsApp] = useState(false);
const [phoneWhatsApp, setPhoneWhatsApp] = useState('');
const [countDownDate, setCountDownDate] = useState(moment().add(60, 'second').format("YYYY-MM-DD HH:mm:ss"));
const [countDownKey, setCountDownKey] = useState(Math.random());

const [activePanel, setActivePanel] = useState('main');
const [isButtonLoading, setIsButtonLoading] = useState(false);
const [fingerUID, setFingerUID] = useState(sha256(new Fingerprint({canvas: true, screen_resolution: true}).get()).toString());

const [response, setResponse] = useState([]);

function InitLogin(mode) {
	setIsButtonLoading(true);
	var formData = new FormData();
	formData.append('phone', phone);
	formData.append('pin', code);
	formData.append('key', key);
	formData.append('FingerUID', fingerUID);
	formData.append('method', 'check_code');

	if (mode === 'edit_name') {
		formData.append('name', name);
		formData.append('surname', surname);
		if (!phoneIsWhatsApp) {
			formData.append('another_number', phoneWhatsApp);
		}
	}

	axios.post('https://impuls200.ru/views/ajax/api/frontoffice/Login.php', formData).then((REQ) => {
		setIsButtonLoading(false);
	    if (REQ.status === 200) {
	    	setResponse(REQ.data?.response);
	    	if (REQ.data?.response?.is_profile === false) {
	    		setActivePanel('edit_name');
	    	} else {
	    		if (REQ.data?.response?.token !== undefined) {
		    		props.setTokenApi(REQ.data?.response?.token);
		    		localStorage.setItem('STUA', REQ.data?.response?.token);
	    		}
	    	}
	    }
  	});
}

function NextStep() {
	setIsButtonLoading(true);
	var formData = new FormData();
	formData.append('phone', phone); 
	formData.append('method', 'send_code');
	formData.append('keyBind', localStorage.getItem('key_bind'));
	formData.append('FingerUID', fingerUID);
	setCountDownDate(moment().add(60, 'second').format("YYYY-MM-DD HH:mm:ss"));
	axios.post('https://impuls200.ru/views/ajax/api/frontoffice/Login.php', formData).then((REQ) => {
		setIsButtonLoading(false);
	    if (REQ.status === 200) {
	    	setResponse(REQ.data?.response);
	    	setKey(REQ.data?.response?.key);
	    	if (REQ.data?.response?.error_code !== 100 && REQ.data?.response?.error_code !== 300 && REQ.data?.response?.error_code !== 200 && REQ.data?.response?.error_code !== 2000) {
	    		setActivePanel('confirmation');
	    	} else {
	    		setActivePanel('main');
	    	}
	    }
  	});
}

useEffect(() => {
	if (code.length > 3) {
		InitLogin('confirmation');
	}
	var tempRes = response;
	delete tempRes?.error_code;
	delete tempRes?.error;
	setResponse(tempRes);
}, [code]);

useEffect(() => {
	setCountDownKey(Math.random());
}, [countDownDate]);

return (
    <SplitLayout style={window.screen.width < 1023 ? undefined : {justifyContent: 'center', alignItems: 'center'}} >
      	<SplitCol style={{ 'height': 'unset' }} width="50%" stretchedOnMobile maxWidth={360}>
      		<View activePanel={activePanel}>
		      	<Panel id='main'>
		        	<PanelHeader style={{fontFamily: 'GilroyExtraBold'}} before={<PanelHeaderButton aria-label="" onClick={() => props.setTheme(props.theme == 'dark' ? 'light' : 'dark')}><Icon20SunOutline/></PanelHeaderButton>}>IMPULS200 | Вход</PanelHeader>
					<Group>
						{response?.error?.length > 0 && <FormStatus header="Что-то пошло не так :(" mode="default">
					    	{response?.error}
					    </FormStatus>}
					    <FormItem top="Страна (Сountry)" htmlFor="administrator-select-id" style={{ flexGrow: 1, flexShrink: 1 }}>
				          <CustomSelect
				          	defaultValue="ru"
				            id="administrator-select-id"
				            placeholder="Не выбран"
				            options={[{ label: "Россия", value: 'ru' }, { label: "Қазақстан", value: 'kz' }, { label: "Беларусь", value: 'by' }]}
				            allowClearButton
				          />
				        </FormItem>
			            <FormItem top="Номер телефона">
			              <Input after={<Icon24PhoneOutline/>} onChange={e => setPhone(e.target.value)} value={phone} type="tel" name="phone" />
			            </FormItem>
			            <FormItem>
			              <Button loading={isButtonLoading} onClick={() => NextStep()} size="l" stretched>
			              	Продолжить
			              </Button>
			            </FormItem>
					</Group>

					{props.Footer}

				</Panel>

				<Panel id='confirmation'>
		        	<PanelHeader style={{fontFamily: 'GilroyExtraBold'}} before={<PanelHeaderButton aria-label="1" onClick={() => {setActivePanel('main'); setResponse([]);}}><Icon24BrowserBack/></PanelHeaderButton>}>IMPULS200 | Вход</PanelHeader>
					<Group>

					    {response?.msg?.length > 0 && <Title level="3" style={{ margin: '16px', textAlign: 'center', fontFamily:'GilroyGilroyLight' }}>
					    	{response?.msg}
					  	</Title>}

			            <FormItem bottom={response?.error?.length > 0 && response?.error} status={response?.error?.length > 0 && "error"}>
			              <Input align="center" placeholder="• • • •" after={<Icon28PincodeOutline/>} onChange={e => setCode(e.target.value)} type="number" name="code" />
			            </FormItem>
			           	<FormItem>
				            <Button loading={isButtonLoading} onClick={() => InitLogin('confirmation')} size="l" stretched>
				              	Продолжить
				            </Button>
			            </FormItem>
			            <FormItem>
			            	<span style={{color: 'var(--vkui--color_text_subhead)'}}><Subhead level="3">В случае если у вас возникли проблемы со входом, или вы не имеете возможности получить код на этот номер телефона, вы можете обратиться к личному менеджеру, он предоставит вам сервисный код для входа.</Subhead></span>
				            <Spacing size={24}>
					        	<Separator />
					      	</Spacing>
				            <RichCell disabled after={<Link target="_blank" href={'whatsapp://send?phone=' + response?.staff_help?.phone}><RichCell.Icon aria-hidden><Icon20MessagesOutline /></RichCell.Icon></Link>} before={<Icon28UsersCircleFillYellow/>} caption={"Менеджер" + ' · ' + response?.staff_help?.phone?.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}>
						          {response?.staff_help?.name + ' ' + response?.staff_help?.surname}
						    </RichCell>
					    </FormItem>
			            {/*response?.error_code === 400 || response?.error_code === undefined &&*/ <FormItem>
				            <Countdown key={countDownKey} date={countDownDate} renderer={({ days, hours, minutes, seconds, completed }) => {
					          	return <Button mode="link" disabled={!completed} onClick={() => NextStep()} size="m" stretched>
				              		Запросить новый код {!completed && ' через ' + seconds + ' сек.'}
				            	</Button>
					        }}
					      />
				        </FormItem>}

					</Group>

					{props.Footer}

				</Panel>

				<Panel id='edit_name'>
		        	<PanelHeader style={{fontFamily: 'GilroyExtraBold'}} before={<PanelHeaderButton aria-label="1" onClick={() => {setActivePanel('main'); setResponse([]);}}><Icon24BrowserBack/></PanelHeaderButton>}>IMPULS200 | Давайте знакомиться</PanelHeader>
					<Group>
						{response?.error?.length > 0 && <FormStatus header="Что-то пошло не так :(" mode="default">
					    	{response?.error}
					    </FormStatus>}
			            <FormItem top="Фамилия">
			              <Input onChange={e => setSurname(e.target.value)} type="text" name="surname" />
			            </FormItem>
			            <FormItem top="Имя">
			              <Input onChange={e => setName(e.target.value)} type="text" name="name" />
			            </FormItem>
			            {phoneIsWhatsApp === false && <FormItem top="Дополнительный телефон WhatsApp">
			              <Input onChange={e => setPhoneWhatsApp(e.target.value)} type="tel" name="WhatsApp" />
			            </FormItem>}
			            <FormItem bottom="На ранее указанный номер телефона, менеджер может отправить дополнительную информацию о фотосесии">
							<Checkbox checked={phoneIsWhatsApp} onChange={event => setPhoneIsWhatsApp(event.target.checked)}>
								Я могу принимать сообщения в WhatsApp на ранее указанный номер
							</Checkbox>
						</FormItem>
			            <FormItem>
			              <Button loading={isButtonLoading} onClick={() => InitLogin('edit_name')} size="l" stretched>
			              	Подтвердить
			              </Button>
			            </FormItem>
					</Group>

					{props.Footer}

				</Panel>

			</View>
      	</SplitCol>
    </SplitLayout>
  );
};

export default login;
