import React, { useState, useEffect } from "react";
import { Panel, PanelHeader, Footer, ScreenSpinner, Spacing, Link, FormItem, Gradient,  MiniInfoCell, ButtonGroup, SimpleCell, Progress, Switch, TabbarItem, Slider, SplitLayout, View, SplitCol, Div, HorizontalScroll, Tabbar, Button, FixedLayout, TabsItem, Tabs, Group, Subhead, Separator, Pagination, useAdaptivityConditionalRender, Cell, PanelHeaderContent, Counter, PanelHeaderContext, PanelHeaderBack, Tooltip, RichCell, Placeholder } from '@vkontakte/vkui';
import { useRouteNavigator, useParams } from '@vkontakte/vk-mini-apps-router';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { Icon20MessagesOutline, Icon28LikeOutline, Icon28ListLikeOutline, Icon28PicturePlusOutline, Icon20Info, Icon28SettingsOutline, Icon28ListLikeSlashOutline, Icon28LikeFillRed, Icon28Search, Icon28ChevronBack, Icon16Dropdown, Icon56CancelCircleOutline,Icon28PictureStackOutline, Icon28Hearts2Outline, Icon28MarketLikeOutline, Icon56NarrativeOutline, Icon56LikeOutline } from '@vkontakte/icons';
import ImagesLoaded from 'react-images-loaded';
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import imagesCache from 'images-cache';
import axios from 'axios';

export function App(props) {

const routeNavigator = useRouteNavigator();
const params = useParams('key');

const [data, setData] = useState([]);
const [items, setItems] = useState([]);
const [loadingPreview, setLoadingPreview] = useState(0);
const [imagesLoaded, setImagesLoaded] = useState(false);
const [imagesLoadedValue, setImagesLoadedValue] = useState(0);
const [columnsImage, setColumnsImage] = useState(6);
const [selectImages, setSelectImage] = useState([]);
const [additionalPayment, setAdditionalPayment] = useState(0);
const [contextOpened, setContextOpened] = useState(false);
const [scaleImage, setScaleImage] = useState((window.screen.width < 425 ? 2 : 4));
const [activePanel, setActivePanel] = useState('all');
const [tempSelected, setTempSelected] = useState([]);
const [syncImages, setSyncImages] = useState();

const [indexСurrentImage, setIndexСurrentImage] = useState(0);
const [imageViewer, setImageViewer] = useState(false);

const [pagination_currentPage, setPagination_currentPage] = useState(1);

const [tooltip1, setTooltip1] = useState(false);
const [tooltip2, setTooltip2] = useState(false);
const [tooltip3, setTooltip3] = useState(false);
const [tooltip4, setTooltip4] = useState(false);

const { viewWidth } = useAdaptivityConditionalRender();

useEffect(() => {

	// setData([]);
	// setLoadingPreview(0);
	// setImagesLoaded(false);
	// setImagesLoadedValue(0);
	// setSelectImage([]);
	// setAdditionalPayment(0);
	// setContextOpened(false);
	// setScaleImage(4);
	// setPagination_currentPage(1);

	Init();
}, []);

function Init() {
	props.popout(<ScreenSpinner></ScreenSpinner>);
	var formData = new FormData();
	formData.append('key', params?.key);
	formData.append('token', localStorage.getItem('STUA'));
	formData.append('FingerUID', props.fingerUID);
	axios.post('https://impuls200.ru/views/ajax/api/frontoffice/photosets/Config.php', formData).then((REQ) => {
    if (REQ.status === 200) {
			props.popout();
			setData(REQ.data);
    }
  });
};

useEffect(() => {
	if (data?.res?.items?.length > 0) {

		if(data?.res?.intermediate_saving?.length > 0) {
			setSelectImage(data?.res?.intermediate_saving);
		}

    setItems(data?.res?.items.flat());
    imagesCache.load(data?.res?.items.flat().map((i) => {return i.src})).progress(status => {
      setLoadingPreview(Math.round(status.loaded/status.total*100));
    });

	}
}, [data]);

useEffect(() => {
	if (loadingPreview === 100) {
		if (localStorage.getItem('hints_view') !== undefined && localStorage.getItem('hints_view') !== "false") {
			setTooltip1(true);
		}
	}
}, [loadingPreview]);

useEffect(() => {
	setImagesLoadedValue(0);
	setImagesLoaded(false);
}, [activePanel]);

useEffect(() => {
  if (selectImages.length > data?.res?.available_selection) {
    let summ = selectImages.length - data?.res?.available_selection;
    setAdditionalPayment(summ*data?.res?.unit_price);
  } else {
    setAdditionalPayment(0);
  }

  if (syncImages > 0) {
	  var formData = new FormData();
		formData.append('key', params?.key);
		formData.append('images', selectImages);
		formData.append('token', localStorage.getItem('STUA'));
		formData.append('FingerUID', props.fingerUID);
		axios.post('https://impuls200.ru/views/ajax/api/frontoffice/photosets/IntermediateSaving.php', formData);
  }

}, [selectImages]);

const selectImage = (uid) => {
	if (selectImages.indexOf(uid) > -1) {
		setSelectImage([...selectImages.slice(0, selectImages.indexOf(uid)), ...selectImages.slice(selectImages.indexOf(uid) + 1)]);
	} else {
		setSelectImage(oldArray => [...oldArray, uid]);
	}
	setSyncImages(Math.floor(Math.random() * (Math.floor(999) - Math.ceil(1)) + Math.ceil(1)));
}

const CleanSelected = () => {
	setSelectImage([]);
	setSyncImages(Math.floor(Math.random() * (Math.floor(999) - Math.ceil(1)) + Math.ceil(1)));
}

function changeView(mode) {
	setTempSelected(items.filter(g => selectImages.includes(g.uid)));
	setActivePanel(mode);
}

const zoomImage = (uid) => {
  setIndexСurrentImage(items.findIndex(p => p.uid === uid));
  setImageViewer(true);
}

const activePanelStyles = {
	backgroundColor: 'var(--vkui--color_background_secondary)',
	borderRadius: 8,
};

return (
	<Panel id={props.id}>
		<PanelHeader before={<PanelHeaderBack onClick={() => routeNavigator.back()}/>} after={props.PanelHeaderAfterProfile}>
			<Tooltip isShown={tooltip1} onClose={() => {setTooltip1(false); setTooltip2(true);}} cornerOffset={0} header="Привет, это подсказки!" text="Тут можно посмотреть количество выбранных фото, а если нажать на заголовок то можно уменьшить масштаб фото или узнать подробную информацию о выбранных фото">
	      <PanelHeaderContent disabled={data?.res?.items?.length > 0 ? false : true} aside={
	        data?.res?.items?.length > 0 && <Icon16Dropdown
	          style={{
	            transform: `rotate(${contextOpened ? '180deg' : '0'})`,
	          }}
	        />} onClick={() => {setContextOpened((prev) => !prev)}}>Выбор фото</PanelHeaderContent>
	    </Tooltip>
		</PanelHeader>

		<PanelHeaderContext opened={contextOpened} onClose={() => {setContextOpened((prev) => !prev)}}>
			<>
				{loadingPreview === 100 &&
					<><FormItem top={<span id="with-step">Масштаб фото</span>}>
		        <Slider defaultValue={scaleImage} onChange={setScaleImage} withTooltip step={1} min={1} max={5}/>
		      </FormItem></>}
		    <SimpleCell Component="label" after={<Switch onClick={() => props.setTheme(props.theme == 'dark' ? 'light' : 'dark')} defaultChecked={props.theme === 'dark' ? true : false} />}>Тёмная тема</SimpleCell>
	    </>
		</PanelHeaderContext>

    <SplitLayout header={false} width="100%">{data?.res?.items?.length > 0 &&
    	<>
    	<SplitCol>

			  {data?.res?.items?.length > 0 && 
					loadingPreview !== 100 && imagesLoaded === false && <FormItem style={{animation: 'BoxAnimation 0.7s forwards'}} id="progresslabel" top={"Загрузка фото... ("+loadingPreview+"%)"}>
		        <Progress aria-labelledby="progresslabel" value={loadingPreview} />
		      </FormItem>
				}

				<View nav="Select_ModeView" activePanel={activePanel}>
					<Panel id="all">
						{loadingPreview === 100 && <ImagesLoaded onProgress={(instance, image) => {setImagesLoadedValue(instance);}} done={() => {setImagesLoaded(true)}}>
							<>
								{loadingPreview === 100 && imagesLoaded === false && <FormItem style={{animation: 'BoxAnimation 0.7s forwards'}} id="progresslabel1" top={"Генерация сетки изображений..."}>
					        <Progress aria-labelledby="progresslabel1" value={Math.round(imagesLoadedValue?.images?.length/imagesLoadedValue?.progressedCount*100)} />
					      </FormItem>}
					      {loadingPreview === 100 && imagesLoaded === true && <div className={viewWidth.tabletMinus.className} style={{display: 'flex', justifyContent: 'center', margin: '5px 0'}}>
					      	<HorizontalScroll><Pagination onChange={(page) => setPagination_currentPage(page)} currentPage={pagination_currentPage} siblingCount={3} boundaryCount={0} totalPages={data?.res?.items?.length} /></HorizontalScroll>
								</div>}
					     	<FormItem>
						      <ResponsiveMasonry style={{ display: imagesLoaded ? 'unset' : 'none' }} columnsCountBreakPoints={{425: scaleImage, 768: scaleImage, 900: scaleImage}}>
						       <Masonry gutter="8px">
						          {data?.res?.items?.length > 0 && data?.res?.items[pagination_currentPage-1].map((image, i) => (
						            <div key={i} className={"WrapItemImage " + (selectImages.indexOf(image.uid) !== -1 ? 'ItemImage--Selected' : '')} style={{position: "relative"}}>
						              <img onClick={() => selectImage(image.uid)} alt="" src={imagesCache.get(image.src)} style={{width: "100%", display: "block", borderRadius: '12px'}} />
						              <div className="actionImage--Wrap">
							              <div className="actionImage--Selected">
								              <Tooltip isShown={i === 0 && tooltip2 ? true : false} onClose={() => {setTooltip2(false); setTooltip3(true);}} cornerOffset={0} text="Хотите получить это фото? Скорее ставьте лайк этому фото :)">
								              	<div onClick={() => selectImage(image.uid)} className="actionImage--Item">{selectImages.indexOf(image.uid) !== -1 ? <Icon28LikeFillRed className="actionImage--Item-Icon" width={20} height={20}/> : <Icon28LikeOutline className="actionImage--Item-Icon" width={20} height={20}/>} </div>
								              </Tooltip>
							              </div>
							              <Separator />
							              <div className="actionImage--Selected">
							              	<Tooltip isShown={i === 1 && tooltip3 ? true : false} onClose={() => {setTooltip3(false); setTooltip4(true);}} cornerOffset={0} text="Разверните фото на весь экран если плохо видно">
							              		<div onClick={() => zoomImage(image.uid)} className="actionImage--Item"><Icon28Search width={20} height={20}/></div>
							              	</Tooltip>
							              </div>
						              </div>
						            </div>
						          ))}
						        </Masonry>
						      </ResponsiveMasonry>
					      </FormItem>
					      {loadingPreview === 100 && imagesLoaded === true && <div className={viewWidth.tabletMinus.className} style={{display: 'flex', justifyContent: 'center', margin: '19px 0 0 0px'}}>
					      	<HorizontalScroll><Pagination onChange={(page) => setPagination_currentPage(page)} currentPage={pagination_currentPage} siblingCount={3} boundaryCount={0} totalPages={data?.res?.items?.length} /></HorizontalScroll>
								</div>}
					    </>
				    	</ImagesLoaded>}
				   	</Panel>
				   	<Panel id="selected">
							{loadingPreview === 100 && <ImagesLoaded onProgress={(instance, image) => {setImagesLoadedValue(instance);}} done={() => {setImagesLoaded(true)}}>
					     	<FormItem>
						      <ResponsiveMasonry style={{ display: imagesLoaded ? 'unset' : 'none' }} columnsCountBreakPoints={{425: scaleImage, 768: scaleImage, 900: scaleImage}}>
						       <Masonry gutter="8px">
						          {tempSelected.length > 0 && tempSelected.map((image, i) => (
						            <div key={i} className={"WrapItemImage " + (selectImages.indexOf(image.uid) !== -1 ? 'ItemImage--Selected' : '')} style={{position: "relative"}}>
						              <img onClick={() => selectImage(image.uid)} alt="" src={imagesCache.get(image.src)} style={{width: "100%", display: "block", borderRadius: '12px'}} />
						              <div className="actionImage--Wrap">
							              <div className="actionImage--Selected">
								              <div onClick={() => selectImage(image.uid)} className="actionImage--Item">{selectImages.indexOf(image.uid) !== -1 ? <Icon28LikeFillRed className="actionImage--Item-Icon" width={20} height={20}/> : <Icon28LikeOutline className="actionImage--Item-Icon" width={20} height={20}/>} </div>
							              </div>
							              <Separator />
							              <div className="actionImage--Selected">
							              	<div onClick={() => zoomImage(image.uid)} className="actionImage--Item"><Icon28Search width={20} height={20}/></div>
							              </div>
						              </div>
						            </div>
						          ))}
						        </Masonry>
						      </ResponsiveMasonry>
					      </FormItem>
					    </ImagesLoaded>}
		    			{tempSelected.length < 1 &&
						    <Placeholder stretched icon={<Icon56NarrativeOutline />} header="Эх, пока тут пусто">
						      Пока тут пусто, но надеемся что это временно. Скорее ставьте лайк тем фото которые хотите получить.
						    </Placeholder>
					    }
				   	</Panel>
		    </View>
				<div style={{marginBottom: '76px'}}>{props.Footer}</div>
	    </SplitCol>
	    <SplitCol className={viewWidth.tabletPlus.className} fixed width={300} maxWidth={300}>
	    	<Group>
	    		<Cell onClick={() => {changeView('all')}} disabled={activePanel === 'all'} style={activePanel === 'all' ? activePanelStyles : undefined} before={<Icon28PictureStackOutline fill="#ba9c80" />}>Все фото</Cell>
	    		<Cell onClick={() => {changeView('selected')}} disabled={activePanel === 'selected'} style={activePanel === 'selected' ? activePanelStyles : undefined} indicator={<Counter mode="primary">{selectImages.length}</Counter>} before={<Icon28Hearts2Outline fill="#ba9c80" />}>Выбранные фото</Cell>
	    		<FormItem top={<span id="with-step">Масштаб фото</span>}>
		        <Slider value={scaleImage} onChange={setScaleImage} withTooltip step={1} min={1} max={5}/>
		      </FormItem>
		    	<RichCell multiline caption={"Бонусных фото: " + data?.res?.available_selection} disabled>
		        Доплата за выбранные фото: {additionalPayment + "₽"}
		      </RichCell>
		      <FormItem>
			      <Button onClick={() => routeNavigator.push('/'+params?.key+'/confirmation/', {state: {images: selectImages, additionalPayment: additionalPayment, orgCollection: data?.res?.org_collection, availableSelection: data?.res?.available_selection, key: params?.key}})} size="l" stretched>
				    	Сохранить выбор
				    </Button>
				  </FormItem>
			    {activePanel === 'selected'  && selectImages.length > 0 && <Button onClick={() => CleanSelected()} appearance="neutral" size="s" mode="link" stretched>
			    	Очистить выбор
			    </Button>}
	      </Group>
	      {activePanel === 'all' && loadingPreview === 100 && imagesLoaded === true && <div style={{display: 'flex', justifyContent: 'center', margin: '5px 0'}}>
		      	<HorizontalScroll><Pagination onChange={(page) => setPagination_currentPage(page)} currentPage={pagination_currentPage} siblingCount={3} boundaryCount={0} totalPages={data?.res?.items?.length} /></HorizontalScroll>
				</div>}
	    </SplitCol>
	    </>}
    </SplitLayout>

		{data?.error?.length > 0 &&
			<>
			  <Placeholder stretched icon={<Icon56CancelCircleOutline />} header="Упс, ошибка!">
			    {data?.error}
			  </Placeholder>
			  <div style={{marginBottom: '76px'}}>{props.Footer}</div>
			</>
		}

		{viewWidth.tabletMinus && data?.res?.items?.length > 0 && 
			<FixedLayout style={{paddingTop: '7px', borderRadius: '21px 21px 0px 0px'}} filled="true" className={viewWidth.tabletMinus.className} useParentWidth={true} vertical="bottom">
  			<MiniInfoCell disabled before={<Icon20Info  />} textWrap="short" expandable >
          {data?.res?.items?.length > 0 ? ("Выбрано "+ selectImages.length + (data?.res?.available_selection > 0 ? (" из "+data?.res?.available_selection+" бесплатных") : '') + " фото") : ('')}
        </MiniInfoCell>
		    <Div>
			    <ButtonGroup style={{display: 'flex'}} align="center" mode="horizontal" gap="s" >
				    {activePanel === 'all' ? <Button onClick={() => {changeView('selected')}} stretched rounded="true" size="m">
				    	Проверить выбор - {additionalPayment + '₽'}
				    </Button> : <Button stretched rounded="true" onClick={() => routeNavigator.push('/'+params?.key+'/confirmation/', {state: {images: selectImages, additionalPayment: additionalPayment, orgCollection: data?.res?.org_collection, availableSelection: data?.res?.available_selection, key: params?.key}})} size="m">
				    	Сохранить выбор - {additionalPayment + '₽'}
				    </Button>}
				    {activePanel === 'selected' && <Button onClick={() => {changeView('all')}} rounded="true" size="m" ><Icon28PicturePlusOutline/></Button>}
				    <Button onClick={() => {setContextOpened((prev) => !prev)}} rounded="true" size="m" ><Icon28SettingsOutline/></Button>
				  </ButtonGroup>
		    </Div>
			</FixedLayout>}

		{imageViewer && <Lightbox
        mainSrc={imagesCache.get(items[indexСurrentImage].src)}
        nextSrc={imagesCache.get(items[(indexСurrentImage + 1) % items.length].src)}
        prevSrc={imagesCache.get(items[(indexСurrentImage + items.length - 1) % items.length].src)}
        onCloseRequest={() => setImageViewer(false)}
        onLike={<div className="actionImage--Selected">
            <div onClick={() => selectImage(items[indexСurrentImage].uid)} className="actionImage--Item">{selectImages.indexOf(items[indexСurrentImage].uid) !== -1 ? <Icon28LikeFillRed width={56} height={56}/> : <Icon56LikeOutline />} </div>
        </div>}
        onMovePrevRequest={() => setIndexСurrentImage((indexСurrentImage + items.length - 1) % items.length)}
        onMoveNextRequest={() => setIndexСurrentImage((indexСurrentImage + 1) % items.length)} />
    }

	</Panel>
)};