import '@vkontakte/vkui/dist/vkui.css';
import './App.css';

import { ReactComponent as Logo } from './img/logo.svg';

import React, { useState, useEffect  } from 'react';
import { Root, View, ScreenSpinner, Spinner, AdaptivityProvider, Link, AppRoot, ConfigProvider,Gradient,Title,SplitLayout, Switch, Spacing, SimpleCell, PanelSpinner, Footer, Banner, IconButton, SplitCol, usePlatform, PanelHeaderButton, useAdaptivityConditionalRender, ModalCard, ModalRoot, ModalPage, Badge , PanelHeaderBack , ModalPageHeader, Group, CellButton, Platform,PanelHeader, Panel, Cell, Epic, Tabbar, TabbarItem ,Counter , Separator,Avatar, Placeholder, Button, Alert } from '@vkontakte/vkui';
import { Icon28PictureStackOutline, Icon28More, Icon28ListLikeOutline, Icon28AccessibilityOutline, Icon28ClockOutline, Icon28SoundWaveOutline, Icon28MarketLikeOutline, Icon56EventOutline, Icon28Cards2Outline, Icon28Users3Outline, Icon28GraphOutline, Icon28MessageArrowRightOutline, Icon28Smiles2Outline, Icon28DoorArrowRightOutline, Icon28MoneyHistoryBackwardOutline, Icon28MoneyTransferOutline, Icon28NotebookAddBadgeOutline, Icon28HandHeartOutline, Icon28MessagesOutline } from '@vkontakte/icons';
import { useActiveVkuiLocation, useGetPanelForView, useRouteNavigator, useParams } from '@vkontakte/vk-mini-apps-router';

import Login from './Login';

import {App as Photosets_PanelHome} from './panels/Photosets/Main';
import {App as Photosets_PanelSelect} from './panels/Photosets/Select';
import {App as Legal_PanelHome} from './panels/Legal/Home';
import {App as Projects_PanelHome} from './panels/Projects/Home';
import {App as Profile_PanelHome} from './panels/Profile/Home';
import {App as Flow_PanelHome} from './panels/Flow/Home';
import {App as Projects_ViewHome} from './panels/Projects/View';

import {Modal as Photoset_Modal_Confirmation} from './modals/Photosets/Confirmation';
import {Modal as Photoset_Modal_Success} from './modals/Photosets/Success';
import {Modal as Profile_Modal} from './modals/Profile';

import axios from 'axios';

import Fingerprint from 'fingerprintjs';
import sha256 from "crypto-js/sha256";

const App = (props) => {

	const [userData, setUserData] = useState();
	const [tokenApi, setTokenApi] = useState(localStorage.getItem('STUA') !== null ? localStorage.getItem('STUA') : '');
	const [fingerUID, setFingerUID] = useState(sha256(new Fingerprint({canvas: true, screen_resolution: true}).get()).toString());

	const [popout, setPopout] = useState();
	const platform = usePlatform();
	const { viewWidth } = useAdaptivityConditionalRender();

  const {
    view: activeView,
    panel: activePanel,
    root: activeRoot,
    modal: activeModal,
    panelsHistory,
  } = useActiveVkuiLocation(); 

  const routeNavigator = useRouteNavigator();
  const getBindKey = useParams('key_bind');

	useEffect(() => {
		if (activeView === 'login_view') {
			props.setPlatform('vkcom');
		} else {
			props.setPlatform('android');
		}
	}, [activeView]);

  useEffect(() => {

  	if (activeRoot === 'bind') {
  		localStorage.setItem('key_bind', getBindKey?.key_bind);
  		routeNavigator.push('/');
  	}

		if (tokenApi.length > 20) {
			if (activeView === 'login_view') {
				routeNavigator.push('/');
			}

			setPopout(<ScreenSpinner></ScreenSpinner>);
			var formData = new FormData();
			formData.append('keyBind', localStorage.getItem('key_bind'));
			formData.append('token', localStorage.getItem('STUA'));
			formData.append('FingerUID', fingerUID);
			axios.post('https://impuls200.ru/views/ajax/api/frontoffice/accounts/thisUserGet.php', formData).then((REQ) => {
		    if (REQ.status === 200) {
		    	localStorage.removeItem('key_bind');
		    	setUserData(REQ.data?.response?.user_data);
					setPopout();
		    }
		  });

		} else {
			routeNavigator.push('/login');
		}
	}, [tokenApi]);

	function logout() {
		setPopout(
      <Alert
        actions={[
          {
            title: 'Отмена',
            autoClose: true,
            mode: 'cancel',
          },
          {
            title: 'Выйти',
            autoClose: true,
            mode: 'destructive',
            action: () => {setTokenApi('');localStorage.removeItem('STUA');localStorage.removeItem('key_bind');},
          },
        ]}
        actionsLayout="horizontal"
        dismissButtonMode="inside"
        onClose={() => setPopout()}
        text="Вы уверены, что хотите выйти из личного кабинета?"
      />,
    );
	}

  const modal = (
    <ModalRoot onClose={() => routeNavigator.hideModal()} activeModal={activeModal}>
    	<Profile_Modal logout={logout} userData={userData} fingerUID={fingerUID} id="profile" />
    	<Photoset_Modal_Confirmation userData={userData} fingerUID={fingerUID} id="Photoset_Modal_Confirmation" />
    	<Photoset_Modal_Success userData={userData} fingerUID={fingerUID} id="Photoset_Modal_Success" />
    </ModalRoot>
  );

	const hasHeader = platform !== Platform.VKCOM;

	const activePanelStyles = {
		backgroundColor: 'var(--vkui--color_background_secondary)',
		borderRadius: 8,
	};

	const FooterBox = (
		<Footer>
			© 2021-2024, ИМПУЛЬС.ТЕХНОЛОГИИ
			<Spacing size={16}/>
			<div style={{display: 'flex', justifyContent: 'center', gap: '11px', flexWrap: 'wrap'}}>
     		<Link href="mailto:imp.200@yandex.ru" style={{fontSize: '11px'}}>Техническая поддержка</Link>
     		<Link onClick={(e) => routeNavigator.push('/legal')} style={{fontSize: '11px'}}>Правовая информация</Link>
     		<Link target="_blank" href="https://impuls200.ru/JoinTeam" style={{fontSize: '11px'}}>Присоединиться к команде</Link>
	    </div>
		</Footer>
  );

const PanelHeaderAfterProfile = userData && (
   <Avatar onClick={() => routeNavigator.showModal('profile')} size={32} src={userData.ava_url.length > 0 ? userData.ava_url : undefined} initials={userData.name[0]+userData.surname[0]} gradientColor="blue">
  	<Avatar.BadgeWithPreset preset="online"/>
  </Avatar>
);
	
  return (
  	<>
	  	<Root style={{display: activeRoot !== 'login' ? 'none' : 'unset'}} nav="login" activeView={activeView}>
		  	<View nav="login_view" activePanel={activePanel}>
		  		<Login nav='panel_login' viewWidth={viewWidth} theme={props.theme} setTheme={props.setTheme} setUserData={setUserData} setTokenApi={setTokenApi} Footer={FooterBox} />
		  	</View>
	  	</Root>
	  	<Root style={{display: activeRoot !== 'bind' ? 'none' : 'unset'}} nav="bind" activeView={activeView}>
		  	<View nav="bind_view" activePanel={activePanel}>
		  		<Panel nav="loading">
		  			<PanelSpinner>Панель загружается, пожалуйста, подождите...</PanelSpinner>
		  		</Panel>
		  	</View>
	  	</Root>
	  	<Root style={{display: activeRoot !== 'home' ? 'none' : 'unset'}} nav="home" activeView={activeView}>
	  		{tokenApi.length < 20 ? (<Panel nav='home_view'><ScreenSpinner state="loading" /></Panel>) :
					(<SplitLayout nav='home_view' popout={popout} modal={modal} header={hasHeader && <PanelHeader separator={false} />} >
						{viewWidth.tabletPlus && (
			        <SplitCol className={viewWidth.tabletPlus.className} fixed autoSpaced width="17%" >
			          <Panel>
			            <PanelHeader style={{fontFamily: 'Smooch', color: '#fed42b'}}>IMPULS<span style={{color: '#ba9c80'}}>200</span></PanelHeader>
			            <Group>
			            	<Gradient mode="tint" to='top' style={{margin: 0,display: 'flex',flexDirection: 'column',alignItems: 'center',justifyContent: 'center',textAlign: 'center', marginBottom: '101px', height: '123px',background: 'url(http://s3.impuls200.ru/static/photo-1589356999083-87c91ea55830.jpg)',backgroundPosition: 'center',backgroundSize: 'cover',borderRadius: '9px'}}>
								      <div style={{position: 'absolute', marginBottom: '-148px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
									      <Avatar size={96} src={userData?.ava_url?.length > 0 ? userData.ava_url : undefined} initials={userData?.name[0]+userData?.surname[0]} gradientColor="orange">
									      	<Avatar.BadgeWithPreset preset="online" />
									      </Avatar>
									      <Title style={{ marginBottom: 8, marginTop: 20 }} level="2" weight="2">
									        {userData?.name + ' ' + userData?.surname}
									      </Title>
								      </div>
								    </Gradient>
			            	{/*<Cell disabled={activePanel === 'Flow_PanelHome'} style={activePanel === 'Flow_PanelHome' ? activePanelStyles : undefined} onClick={(e) => routeNavigator.push('/flow')} before={<Icon28SoundWaveOutline fill="#ba9c80" />}>Мой поток</Cell>*/}
			              <Cell disabled={activePanel === 'Photosets_PanelHome'} style={activePanel === 'Photosets_PanelHome' ? activePanelStyles : undefined} onClick={(e) => routeNavigator.push('/photosets')} before={<Icon28PictureStackOutline fill="#ba9c80" />}>Мои фотосеты</Cell>
			              {/*<Cell disabled={activePanel === 'Projects_PanelHome'} style={activePanel === 'Projects_PanelHome' ? activePanelStyles : undefined} onClick={(e) => routeNavigator.push('/projects')} before={<Icon28Cards2Outline fill="#ba9c80"/>}>Проекты</Cell>*/}
			              <CellButton mode="danger" onClick={() => {logout()}} before={<Icon28DoorArrowRightOutline />}>Выйти</CellButton>
			              <Spacing size={16}><Separator /></Spacing>
			              <SimpleCell Component="label" after={<Switch onClick={() => props.setTheme(props.theme == 'dark' ? 'light' : 'dark')} defaultChecked={props.theme === 'dark' ? true : false} />}>Тёмная тема</SimpleCell>
			            </Group>
			            <Banner style={{padding: 'unset'}} mode="image" header="Порадуйте своих друзей приятным подарком!" subheader="За каждого друга, кто примет участие в бесплатной фотосессии, начислим Вам бонусы для оплаты съёмок от нашей команды." background={
						          <div
						            style={{
						              backgroundColor: '#65c063',
						              backgroundImage:
						                'url(https://s3.impuls200.ru/static/aArX55153Zr5of-J1aLK.png)',
						              backgroundPosition: 'right bottom',
						              backgroundSize: 320,
						              backgroundRepeat: 'no-repeat',
						            }}
						          />
						        }
						        actions={<Link target="_blank" href="https://app.impuls200.ru/GiftFriend"><Button appearance="overlay">Подробнее</Button></Link>}
						      />
			          </Panel>
			        </SplitCol>
			      )}
			      <SplitCol animate={true} width="82%" stretchedOnMobile autoSpaced>
			        <Epic activeStory={activePanel} tabbar={activePanel !== 'Photosets_PanelSelect' && viewWidth.tabletMinus && (
		            <Tabbar className={viewWidth.tabletMinus.className}>
		            		{/*<TabbarItem onClick={(e) => routeNavigator.push('/flow')} selected={activePanel === 'Flow_PanelHome'} text="Мой поток">
		                  <Icon28SoundWaveOutline />
		                </TabbarItem>*/}
		                <TabbarItem onClick={(e) => routeNavigator.push('/photosets')} selected={activePanel === 'Photosets_PanelHome'} text="Мои фотосеты">
		                  <Icon28PictureStackOutline />
		                </TabbarItem>
		                {/*<TabbarItem onClick={(e) => routeNavigator.push('/projects')} selected={activePanel === 'Projects_PanelHome'} text="Проекты">
		                  <Icon28Cards2Outline />
		                </TabbarItem>*/}
		                <TabbarItem onClick={(e) => routeNavigator.push('/profile')} selected={activePanel === 'Profile_PanelHome'} text="Профиль">
		                  {userData ? <Avatar onClick={() => routeNavigator.showModal('profile')} size={28} src={userData.ava_url.length > 0 ? userData.ava_url : undefined} initials={userData.name[0]+userData.surname[0]} gradientColor="blue"/> : <Icon28More/>}
		                </TabbarItem>
		            </Tabbar>)}>

								<Photosets_PanelHome id="Photosets_PanelHome" PanelHeaderAfterProfile={PanelHeaderAfterProfile} popout={setPopout} Footer={FooterBox} fingerUID={fingerUID}  />
								<Photosets_PanelSelect id="Photosets_PanelSelect" PanelHeaderAfterProfile={PanelHeaderAfterProfile} theme={props.theme} setTheme={props.setTheme} popout={setPopout} Footer={FooterBox} fingerUID={fingerUID} />
								<Legal_PanelHome id="Legal_PanelHome" PanelHeaderAfterProfile={PanelHeaderAfterProfile} popout={setPopout} Footer={FooterBox} fingerUID={fingerUID} />
								<Projects_PanelHome id="Projects_PanelHome" PanelHeaderAfterProfile={PanelHeaderAfterProfile} popout={setPopout} Footer={FooterBox} fingerUID={fingerUID} />
								<Profile_PanelHome id="Profile_PanelHome" logout={logout} theme={props.theme} setTheme={props.setTheme} Footer={FooterBox} fingerUID={fingerUID} />
								<Flow_PanelHome id="Flow_PanelHome" PanelHeaderAfterProfile={PanelHeaderAfterProfile} popout={setPopout} Footer={FooterBox} fingerUID={fingerUID} />
								<Projects_ViewHome id="Projects_ViewHome" PanelHeaderAfterProfile={PanelHeaderAfterProfile} popout={setPopout} Footer={FooterBox} fingerUID={fingerUID} />

		        	</Epic>
			      </SplitCol>
			    </SplitLayout>
			  )}
		  </Root>
	  </>
  );
};

export default App;
