import React, { useState, useEffect, useCallback, createRef } from "react";
import { useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import { Panel, PanelHeader, Footer, Spacing, Link, Group, Accordion, Div} from '@vkontakte/vkui';
import { Icon20MessagesOutline } from '@vkontakte/icons';

export function App(props) {

const routeNavigator = useRouteNavigator();
const [snackbar, setSnackbar] = useState(null);

useEffect(() => {
	Init();
}, []);

function Init() {

};

const infoStyle = { color: 'var(--vkui--color_text_subhead)' };

return (
	<Panel id={props.id}>
		<PanelHeader after={props.PanelHeaderAfterProfile}>Правовая информация</PanelHeader>

		<Group>
	      <Accordion open>
	        <Accordion.Summary iconPosition="before">Политика конфиденциальности</Accordion.Summary>
	        <Div style={infoStyle}>
	         Политика конфиденциальности "IMPULS200"

				<h2>1. Введение</h2>

				<p>1.1. Добро пожаловать в "IMPULS200" – ваш персональный агрегатор услуг (далее - Сервис). Настоящая политика конфиденциальности принадлежит и регулируется Хлебниковым Русланом Олеговичем, плательщиком налога на профессиональный доход (ИНН: 0000000000), владельцем и администратором Сервиса.
				Настоящая политика распортраняется на всю экосистему сервисов указанных в п. 8.1.</p>

				<h2>2. Основные термины</h2>

				<p>2.1. <b>Пользователь</b> - физическое лицо, использующее Сервис "IMPULS200".</p>

				<p>2.2. <b>Партнёры агрегатора</b> - фотографы, менеджеры, организаторы, корректоры фотосессии, в том числе администраторы Сервиса.</p>

				<p>2.3. <b>Персональные данные</b> - информация, предоставленная пользователем для использования Сервиса.</p>

				<p>2.4. <b>Студия</b> - организация или физическое лицо, предоставляющие в аренду студию и/или реквизит для съёмки фотосессии.</p>

				<p>2.5. <b>Готовые фотографии</b> - обработанные фотографии, предназначенные для передачи клиенту.</p>

				<p>2.6. <b>Агрегатор услуг</b> - сервис, который собирает данные из разных источников (чаще всего — информацию о товарах и услугах разных компаний) или же соединяет заказчиков и исполнителей услуг.</p>

				<h2>3. Собираемые данные</h2>

				3.1. Для создания неповторимого опыта мы собираем ограниченный объем информации: ваше имя, фамилию, телефон, IP-адрес и данные о браузере. Эти данные помогают нам обеспечивать надежность и корректную работу нашего сервиса.

				<h2>4. Загрузка фотографий и обработка данных</h2>

				4.1. Наши партнёры, включая фотографов, менеджеров и администраторов, имеют возможность загружать фотографии для вашего выбора. Все обработанные данные, включая персональные, хранятся и удаляются в соответствии с высокими стандартами безопасности.

				<h2>5. Передача данных третьим лицам</h2>

				5.1. Ваши персональные данные остаются конфиденциальными и не передаются третьим лицам, за исключением случаев, когда это необходимо для предоставления вам услуг студий и реквизита для съёмки фотосессии и/или в целях соблюдения отдельных законодательных актов Российской Федерации.

				<h2>6. Обработка фотографий</h2>

				6.1. Исходные фотографии и/или готовые фотографии, в том числе фотографии для последующего выбора (сортировки) могут быть размещены партнёрами сервиса на платформах "Яндекс Диск" и "VK Cloud", обработка таких данных регламентируется политиками соответствующих компаний ООО «Яндекс» (ИНН: 7736207543) и/или ООО «ВК» (ИНН: 7743001840), чтобы обеспечить безопасное и удобное взаимодействие с вашим контентом.

				<h2>7. Служебные и маркетинговые сообщения</h2>

				7.1. Ваши персональные данные могут использоваться для отправки служебных и локальных маркетинговых сообщений в мессенджерах WhatsApp (принадлежащий компании Meta Platforms*) и Telegram, с учетом ваших предпочтений.

				<h2>8. Экосистема сервисов</h2>

				8.1. Наш основной Сервис расположен по адресу https://Impuls200.ru, а также имеет экосистему сервисов, включая https://app.impuls200.ru, https://backoffice.impuls200.ru, https://office.impuls200.ru.

				<h2>9. Заключительные положения</h2>
				<p>9.1. Мы стремимся обеспечить прозрачность и безопасность ваших данных. Настоящая политика регулируется с учетом законодательства, и мы оставляем за собой право вносить изменения в соответствии с развитием технологий и ваших потребностей.</p>
				<p>Благодарим за использование "IMPULS200".</p>

				<p>(* - в Российской Федерации признана экстремистской организацией, её деятельность запрещена)</p>
			</Div>
	      </Accordion>
	      <Accordion>
	        <Accordion.Summary iconPosition="before">Условия использования сервиса</Accordion.Summary>
	        <Div style={infoStyle}>
	          VK Pay — это онлайн-сервис для оплаты товаров и услуг. Храните все банковские карты под
	          рукой, покупайте в онлайн-магазинах, получайте персональные скидки и спецпредложения,
	          оплачивайте ежедневные услуги и переводите деньги друзьям.
	        </Div>
	      </Accordion>
	      <Accordion>
	        <Accordion.Summary iconPosition="before">Пользовательское соглашение</Accordion.Summary>
	        <Div style={infoStyle}>
				<div class="body conbody" data-help-text="1">
					<section class="section" data-title="1. Общие положения">
						<h2 class="title sectiontitle doc-c-headers doc-c-headers_mod_h2">1. Общие положения</h2>
						<p class="p">
							1.1. плательщик НПД Хлебников Руслан Олегович (далее — «IMPULS200») предлагает пользователю сети Интернет (далее – Пользователь) - использовать свои сервисы на условиях, изложенных в настоящем Пользовательском соглашении (далее — «Соглашение», «ПС»).
							Соглашение вступает в силу с момента выражения Пользователем согласия с его условиями в порядке, предусмотренном п. 1.4 Соглашения.
						</p>
						<p class="p">
							1.2. IMPULS200 предлагает Пользователям доступ к широкому спектру сервисов, включая средства навигации, коммуникации, поиска, размещения и хранения разного рода информации и материалов (контента), персонализации контента,
							совершения покупок и т. д. Все существующие на данный момент сервисы плательщик НПД Хлебников Руслан Олегович и других компаний, условия использования которых ссылаются на данное Соглашение (далее – «сервисы IMPULS200а»), а также любое развитие их и/или
							добавление новых является предметом настоящего Соглашения.
						</p>
						<p class="p">
							1.3. Использование сервисов IMPULS200а регулируется настоящим Соглашением, а также следующими документами, которые являются неотъемлемой частью настоящего соглашения: Лицензией на использование поисковой системы IMPULS200а (
							<a class="xref doc-c-link" href="/legal/termsofuse/index.html">https://yandex.ru/legal/termsofuse</a>), Политикой конфиденциальности (
							<a class="xref doc-c-link" href="/legal/confidential/index.html">https://yandex.ru/legal/confidential</a>), Условиями использования IMPULS200 ID (
							<a class="xref doc-c-link" href="https://yandex.ru/legal/id_termsofuse" target="_blank">https://yandex.ru/legal/id_termsofuse</a>), Условиями использования привязанных карт в сервисах IMPULS200а (
							<a class="xref doc-c-link" href="/legal/bank_card/index.html">https://yandex.ru/legal/bank_card</a>), а также условиями использования отдельных сервисов IMPULS200а. Соглашение может быть изменено IMPULS200ом без какого-либо
							специального уведомления, новая редакция Соглашения вступает в силу с момента ее размещения в сети Интернет по указанному в настоящем абзаце адресу, если иное не предусмотрено новой редакцией Соглашения. Действующая редакция ПС
							всегда находится на странице по адресу <a class="xref doc-c-link" href="/legal/rules/index.html">https://yandex.ru/legal/rules</a>.
						</p>
						<p class="p">
							1.4. Начиная использовать какой-либо сервис/его отдельные функции, либо пройдя процедуру регистрации, Пользователь считается принявшим условия Соглашения в полном объеме, без всяких оговорок и исключений. В случае несогласия
							Пользователя с какими-либо из положений Соглашения Пользователь не вправе использовать сервисы IMPULS200а. В случае если IMPULS200ом были внесены какие-либо изменения в Соглашение в порядке, предусмотренном пунктом 1.3 Соглашения, с
							которыми Пользователь не согласен, он обязан прекратить использование сервисов IMPULS200а.
						</p>
					</section>
					<section class="section" data-title="2. Регистрация IMPULS200 ID Пользователя. Авторизация с использованием IMPULS200 ID. ">
						<h2 class="title sectiontitle doc-c-headers doc-c-headers_mod_h2">2. Регистрация IMPULS200 ID Пользователя. Авторизация с использованием IMPULS200 ID.</h2>
						<p class="p">
							2.1. Для того чтобы воспользоваться некоторыми сервисами IMPULS200а или некоторыми отдельными функциями сервисов, Пользователю необходимо пройти процедуру регистрации, в результате которой для Пользователя будет создана уникальная
							учетная запись (IMPULS200 ID).
						</p>
						<p class="p">
							Пользователь, достигший возраста 14 лет, вправе самостоятельно производить регистрацию учетной записи и использовать сервисы IMPULS200а в пределах правоспособности, установленной применимым законодательством. В случае если
							Пользователь не достиг указанного возраста, а также в случаях, когда этого требует применимое законодательство, использование сервисов IMPULS200а допускается только с согласия родителей или иных законных представителей.
						</p>
						<p class="p">Отдельные сервисы IMPULS200а могут установить иные возрастные ограничения, о чем дополнительно может быть указано в правовых документах либо в интерфейсе/описании сайта, приложения и/или иного программного продукта.</p>
						<p class="p">
							2.2. Порядок создания, использования учетной записи (IMPULS200 ID), а также использования функций личного кабинета IMPULS200 ID регулируется Условиями использования IMPULS200 ID, опубликованными по адресу:
							<a class="xref doc-c-link" href="https://yandex.ru/legal/id_termsofuse" target="_blank">https://yandex.ru/legal/id_termsofuse</a>.
						</p>
					</section>
					<section class="section" data-title="3. Общие положения об использовании и хранении">
						<h2 class="title sectiontitle doc-c-headers doc-c-headers_mod_h2">3. Общие положения об использовании и хранении</h2>
						<p class="p">
							3.1. IMPULS200 вправе устанавливать ограничения в использовании сервисов IMPULS200а для всех Пользователей, либо для отдельных категорий Пользователей (в зависимости от места пребывания Пользователя, языка, на котором предоставляется
							сервис и т.д.), в том числе: наличие/отсутствие отдельных функций сервиса, срок хранения почтовых сообщений в сервисе IMPULS200 Почта, любого другого контента, максимальное количество сообщений, которые могут быть отправлены или
							получены одним зарегистрированным пользователем, максимальный размер почтового сообщения или дискового пространства, максимальное количество обращений к сервису за указанный период времени, максимальный срок хранения контента,
							специальные параметры загружаемого контента и т.д. IMPULS200 может запретить автоматическое обращение к своим сервисам, а также прекратить прием любой информации, сгенерированной автоматически (например, почтового спама).
						</p>
						<p class="p">
							IMPULS200, в целях защиты оборудования Пользователя и собственного оборудования, вправе ограничить прием любых сообщений и их доставку Пользователю, когда такие сообщения содержат вредоносные программы или код, либо когда
							автоматические средства фильтрации и антивирусной защиты IMPULS200а определили наличие таких вредоносных программ или кодов в указанных сообщениях. Пользователь уведомлен и согласен с тем, что IMPULS200, в указанных целях, вправе
							осуществлять анализ и исследование таких вредоносных программ и кодов, содержащихся в указанных сообщениях, для улучшения качества работы автоматических средств фильтрации и антивирусной защиты IMPULS200а.
						</p>
						<p class="p">
							3.2. IMPULS200 вправе направлять Пользователю информационные сообщения. IMPULS200 также вправе направлять Пользователю сообщения рекламного характера при условии получения согласия способом, соответствующим требованиям
							законодательства, либо при условии, что Пользователь не отказался от получения сообщений рекламного характера в отношении соответствующего сервиса (при регистрации, либо позднее с использованием соответствующей
							функциональности).
						</p>
						<p class="p">
							Пользователь выражает согласие IMPULS200у на оповещение других Пользователей конкретного сервиса о публичных действиях Пользователя, в том числе о размещении им новых публикаций, о произведенных им действиях в отношении Контента
							других Пользователей, а также иной активности, совершенной им в рамках сервиса.
						</p>
						<p class="p">
							3.3. В целях повышения качества сервисов IMPULS200 и/или привлекаемые им для проведения опроса лица вправе осуществлять сбор мнений и отзывов Пользователей по различным вопросам путем направления информационного сообщения при
							очередном посещении Пользователем сервиса либо осуществления связи по контактным данным, указанным Пользователем в учетной записи (посредством телефонных звонков или электронных писем). Собранные мнения и отзывы могут быть
							использованы для формирования статистических данных, которые могут быть использованы в Сервисах. Отзывы, предоставленные Пользователем при проведении опроса, также могут быть опубликованы IMPULS200ом в сервисах IMPULS200а либо в
							сервисах аффилированных с IMPULS200ом лиц, как с указанием имени (логина) Пользователя, так и без указания. При оставлении отзывов Пользователь обязуется руководствоваться требованиями настоящего Соглашения, в том числе
							требованиями, установленными п. 5 настоящего Соглашения.
						</p>
						<p class="p">
							3.4. При использовании Пользователем сервисов, предоставляемых ООО «Дзен.Платформа», Изображение учетной записи, Имя Пользователя, идентификаторы Пользователя, включая device-id и файлы cookie, адрес электронной почты, номер
							мобильного телефона, информация об использовании Пользователем сервисов IMPULS200а, могут передаваться в ООО «Дзен.Платформа» для целей предоставления сервисов, предоставляемых ООО «Дзен.Платформа» (
							<a class="xref doc-c-link" href="https://dzen.ru/legal/termsofuse" target="_blank">https://dzen.ru/legal/termsofuse</a>,
							<a class="xref doc-c-link" href="/legal/news_termsofuse/index.html">https://yandex.ru/legal/news_termsofuse</a>).
						</p>
					</section>
					<section class="section" data-title="4. Контент Пользователя">
						<h2 class="title sectiontitle doc-c-headers doc-c-headers_mod_h2">4. Контент Пользователя</h2>
						<p class="p">
							4.1. Пользователь самостоятельно несет ответственность за соответствие содержания размещаемого Пользователем контента требованиям действующего законодательства, включая ответственность перед третьими лицами в случаях, когда
							размещение Пользователем того или иного контента или содержание контента нарушает права и законные интересы третьих лиц, в том числе личные неимущественные права авторов, иные интеллектуальные права третьих лиц, и/или посягает
							на принадлежащие им нематериальные блага.
						</p>
						<p class="p">
							4.2. Пользователь признает и соглашается с тем, что IMPULS200 не обязан просматривать контент любого вида, размещаемый и/или распространяемый Пользователем посредством сервисов IMPULS200а, а также то, что IMPULS200 имеет право (но не
							обязанность) по своему усмотрению отказать Пользователю в размещении и/или распространении им контента или удалить любой контент, который доступен посредством сервисов IMPULS200а. Пользователь осознает и согласен с тем, что он
							должен самостоятельно оценивать все риски, связанные с использованием контента, включая оценку надежности, полноты или полезности этого контента.
						</p>
						<p class="p">
							4.3. Пользователь осознает и соглашается с тем, что технология работы сервисов IMPULS200а может потребовать копирование (воспроизведение) контента Пользователя IMPULS200ом, а также переработки его IMPULS200ом для соответствия техническим
							требованиям того или иного сервиса.
						</p>
						<p class="p">
							4.4. При обработке жалоб, поступающих в отношении контента Пользователя, IMPULS200 руководствуется принципами Ограничения прав анонимного контента, опубликованными по адресу
							<a class="xref doc-c-link doc-c-link_type_external" href="https://yandex.ru/company/rules/anonymous_content/" target="_blank">https://yandex.ru/company/rules/anonymous_content</a>, если иное не установлено условиями
							использования сервиса.
						</p>
					</section>
					<section class="section" data-title="5. Условия использования сервисов IMPULS200а">
						<h2 class="title sectiontitle doc-c-headers doc-c-headers_mod_h2">5. Условия использования сервисов IMPULS200а</h2>
						<p class="p">
							5.1. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с использованием сервисов IMPULS200а, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих
							лиц, а также за соблюдение законодательства при использовании сервисов IMPULS200а.
						</p>
						<p class="p">5.2. При использовании сервисов IMPULS200а Пользователь не вправе:</p>
						<p class="p">
							5.2.1. загружать, посылать, передавать или любым другим способом размещать и/или распространять контент, который является незаконным, вредоносным, клеветническим, оскорбляет нравственность, демонстрирует (или является
							пропагандой) насилия и жестокости, нарушает права интеллектуальной собственности, пропагандирует ненависть и/или дискриминацию людей по расовому, этническому, половому, религиозному, социальному признакам, содержит оскорбления в
							адрес каких-либо лиц или организаций, содержит элементы (или является пропагандой) порнографии, детской эротики, представляет собой рекламу (или является пропагандой) услуг сексуального характера (в том числе под видом иных
							услуг), разъясняет порядок изготовления, применения или иного использования наркотических веществ или их аналогов, взрывчатых веществ или иного оружия;
						</p>
						<p class="p">5.2.2. нарушать права третьих лиц, в том числе несовершеннолетних лиц и/или причинять им вред в любой форме;</p>
						<p class="p">
							5.2.3. выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на то прав, в том числе за сотрудников IMPULS200а, за модераторов форумов, за владельца сайта, а также применять любые другие
							формы и способы незаконного представительства других лиц в сети, а также вводить пользователей или IMPULS200 в заблуждение относительно свойств и характеристик каких-либо субъектов или объектов;
						</p>
						<p class="p">5.2.4. загружать, посылать, передавать или любым другим способом размещать и/или распространять контент, при отсутствии прав на такие действия согласно законодательству или каким-либо договорным отношениям;</p>
						<p class="p">
							5.2.5. загружать, посылать, передавать или любым другим способом размещать и/или распространять не разрешенную специальным образом рекламную информацию, спам (в том числе и поисковый), списки чужих адресов электронной почты,
							схемы «пирамид», многоуровневого (сетевого) маркетинга (MLM), системы интернет-заработка и e-mail-бизнесов, «письма счастья», а также использовать сервисы IMPULS200а для участия в этих мероприятиях, или использовать сервисы
							IMPULS200а, исключительно для перенаправления пользователей на страницы других доменов;
						</p>
						<p class="p">
							5.2.6. загружать, посылать, передавать или любым другим способом размещать и/или распространять какие-либо материалы, содержащие вирусы или другие компьютерные коды, файлы или программы, предназначенные для нарушения,
							уничтожения либо ограничения функциональности любого компьютерного или телекоммуникационного оборудования или программ, для осуществления несанкционированного доступа, а также серийные номера к коммерческим программным продуктам
							и программы для их генерации, логины, пароли и прочие средства для получения несанкционированного доступа к платным ресурсам в Интернете, а также размещения ссылок на вышеуказанную информацию;
						</p>
						<p class="p">5.2.7. несанкционированно собирать и хранить персональные данные других лиц;</p>
						<p class="p">5.2.8. нарушать нормальную работу веб-сайтов и сервисов IMPULS200а;</p>
						<p class="p">5.2.9. содействовать действиям, направленным на нарушение ограничений и запретов, налагаемых Соглашением;</p>
						<p class="p">
							5.2.10. воспроизводить, повторять и копировать, продавать и перепродавать, а также использовать для каких-либо коммерческих целей какие-либо части сервисов IMPULS200а (включая контент, доступный Пользователю посредством сервисов
							IMPULS200а), или доступ к ним, кроме тех случаев, когда Пользователь получил такое разрешение от IMPULS200а, либо когда это прямо предусмотрено пользовательским соглашением какого-либо сервиса;
						</p>
						<p class="p">5.2.11. другим образом нарушать нормы законодательства, в том числе нормы международного права.</p>
					</section>
					<section class="section" data-title="6. Исключительные права на содержание сервисов и контент">
						<h2 class="title sectiontitle doc-c-headers doc-c-headers_mod_h2">6. Исключительные права на содержание сервисов и контент</h2>
						<p class="p">
							6.1. Все объекты, доступные при помощи сервисов IMPULS200а, в том числе элементы дизайна, текст, графические изображения, иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки и другие объекты (далее – Содержание
							сервисов IMPULS200а), а также любой контент, размещенный на сервисах IMPULS200а, являются объектами исключительных прав IMPULS200а, Пользователей и других правообладателей.
						</p>
						<p class="p">
							6.2. Использование контента, а также каких-либо иных элементов сервисов IMPULS200а возможно только в рамках функционала, предлагаемого тем или иным сервисом. Никакие элементы Содержания сервисов IMPULS200а, а также любой контент,
							размещенный на сервисах IMPULS200а, не могут быть использованы иным образом без предварительного разрешения правообладателя. Под использованием подразумеваются, в том числе: воспроизведение, копирование, переработка,
							распространение на любой основе, отображение во фрейме и т.д. Исключение составляют случаи, прямо предусмотренные законодательством РФ или условиями использования того или иного сервиса IMPULS200а.
						</p>
						<p class="p">
							Использование Пользователем элементов Содержания сервисов IMPULS200а, а также любого контента для личного некоммерческого использования, допускается при условии сохранения всех знаков охраны авторского права, смежных прав, товарных
							знаков, других уведомлений об авторстве, сохранения имени (или псевдонима) автора/наименования правообладателя в неизменном виде, сохранении соответствующего объекта в неизменном виде. Исключение составляют случаи, прямо
							предусмотренные законодательством РФ или пользовательскими соглашениями того или иного сервиса IMPULS200а.
						</p>
					</section>
					<section class="section" data-title="7. Сайты и контент третьих лиц">
						<h2 class="title sectiontitle doc-c-headers doc-c-headers_mod_h2">7. Сайты и контент третьих лиц</h2>
						<p class="p">
							7.1. Сервисы IMPULS200а могут содержать ссылки на другие сайты в сети Интернет (сайты третьих лиц). Указанные третьи лица и их контент не проверяются IMPULS200ом на соответствие тем или иным требованиям (достоверности, полноты,
							законности и т.п.). IMPULS200 не несет ответственность за любую информацию, материалы, размещенные на сайтах третьих лиц, к которым Пользователь получает доступ с использованием сервисов IMPULS200а, в том числе, за любые мнения или
							утверждения, выраженные на сайтах третьих лиц, рекламу и т.п., а также за доступность таких сайтов или контента и последствия их использования Пользователем.
						</p>
						<p class="p">
							7.2. Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого характера, размещенная на сайте, не является одобрением или рекомендацией данных продуктов (услуг, деятельности) со
							стороны IMPULS200а, за исключением случаев, когда на это прямо указывается на ресурсах IMPULS200а.
						</p>
					</section>
					<section class="section" data-title="8. Реклама на сервисах IMPULS200а">
						<h2 class="title sectiontitle doc-c-headers doc-c-headers_mod_h2">8. Реклама на сервисах IMPULS200а</h2>
						<p class="p">8.1. IMPULS200 несет ответственность за рекламу, размещенную им на сервисах IMPULS200а, в пределах, установленных законодательством РФ.</p>
					</section>
					<section class="section" data-title="9. Отсутствие гарантий, ограничение ответственности">
						<h2 class="title sectiontitle doc-c-headers doc-c-headers_mod_h2">9. Отсутствие гарантий, ограничение ответственности</h2>
						<p class="p">
							9.1. Пользователь использует сервисы IMPULS200а на свой собственный риск. Сервисы IMPULS200а предоставляются «как есть». IMPULS200 не принимает на себя никакой ответственности, в том числе за соответствие сервисов IMPULS200а целям
							Пользователя.
						</p>
						<p class="p">
							9.2. IMPULS200 не гарантирует, что: сервисы IMPULS200а соответствуют/будут соответствовать требованиям Пользователя; сервисы IMPULS200а будут предоставляться непрерывно, быстро, надежно и без ошибок; результаты, которые могут быть
							получены с использованием сервисов IMPULS200а, будут точными и надежными и могут использоваться для каких-либо целей или в каком-либо качестве (например, для установления и/или подтверждения каких-либо фактов); качество какого-либо
							продукта, услуги, информации и пр., полученных с использованием сервисов IMPULS200а, будет соответствовать ожиданиям Пользователя.
						</p>
						<p class="p">
							9.3. Любые информацию и/или материалы (в том числе загружаемое ПО, письма, какие-либо инструкции и руководства к действию и т.д.), доступ к которым Пользователь получает с использованием сервисов IMPULS200а, Пользователь может
							использовать на свой собственный риск и самостоятельно несет ответственность за возможные последствия использования указанных информации и/или материалов, в том числе за ущерб, который это может причинить компьютеру Пользователя
							или третьим лицам, за потерю данных или любой другой вред.
						</p>
						<p class="p">9.4. IMPULS200 не несет ответственности за любые виды убытков, наступившие вследствие использования Пользователем сервисов IMPULS200а или отдельных частей/функций сервисов IMPULS200а.</p>
						<p class="p">
							9.5. При любых обстоятельствах ответственность IMPULS200а в соответствии со статьей 15 Гражданского кодекса Российской Федерации ограничена 10 000 (десятью тысячами) рублей РФ и возлагается на него при наличии в его действиях вины.
						</p>
					</section>
					<section class="section" data-title="10. Иные положения">
						<h2 class="title sectiontitle doc-c-headers doc-c-headers_mod_h2">10. Иные положения</h2>
						<p class="p">10.1. Настоящее Соглашение представляет собой договор между Пользователем и IMPULS200ом относительно порядка использования сервисов IMPULS200а и заменяет собой все предыдущие соглашения между Пользователем и IMPULS200ом.</p>
						<p class="p">
							10.2. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством Российской
							Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением, разрешаются в порядке, установленном действующим законодательством Российской Федерации, по нормам российского права. Везде по тексту
							настоящего Соглашения, если явно не указано иное, под термином «законодательство» понимается как законодательство Российской Федерации, так и законодательство места пребывания Пользователя.
						</p>
						<p class="p">
							10.3. В отношении услуг, оказываемых в рамках настоящего Соглашения и сервисов IMPULS200а на безвозмездной основе, нормы о защите прав потребителей, предусмотренные законодательством Российской Федерации, не могут быть применимыми
							к отношениям между Пользователем и IMPULS200ом.
						</p>
						<p class="p">
							10.4. В случае если сервисы IMPULS200а предусматривают возможность оформления платной подписки, то в целях оформления подписки и дальнейшей возможности оплаты подписки Пользователь привязывает свою банковскую карту к своей учетной
							записи (далее - Привязанная карта). Для целей настоящего Соглашения любая банковская карта, привязанная к учетной записи Пользователя (в том числе привязанная при оформлении подписки на сервисах IMPULS200а либо до оформления
							подписки, либо после ее оформления), считается Привязанной картой. Порядок добавления и использования Привязанных карт регулируется условиями, опубликованными по адресу:
							<a class="xref doc-c-link" href="/legal/bank_card/index.html">https://yandex.ru/legal/bank_card</a>.
						</p>
						<p class="p">
							При совершении Пользователем безналичной оплаты с использованием функций <a class="xref doc-c-link doc-c-link_type_external" href="https://yandex.ru/all" target="_blank">сервисов и приложений IMPULS200а</a> и его аффилированных лиц
							в адрес третьих лиц без авторизации в сервисах IMPULS200а и без <a class="xref doc-c-link" href="/legal/bank_card/index.html">привязки банковской карты</a> к учетной записи Пользователя в сервисах IMPULS200а, IMPULS200 сохраняет
							указанные Пользователем данные в платежной форме исключительно для технических целей проведения оплаты без привязки к учетной записи конкретного Пользователя. Введенная информация сохраняется и обрабатывается на условиях и в
							целях, указанных в Политике конфиденциальности (<a class="xref doc-c-link" href="/legal/confidential/index.html">https://yandex.ru/legal/confidential</a>).
						</p>
						<p class="p">
							10.5. Ничто в Соглашении не может пониматься как установление между Пользователем и IMPULS200ом агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных
							отношений, прямо не предусмотренных Соглашением.
						</p>
						<p class="p">
							10.6. Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных
							положений Соглашения.
						</p>
						<p class="p">
							10.7. Бездействие со стороны IMPULS200а в случае нарушения Пользователем либо иными пользователями положений Соглашений не лишает IMPULS200 права предпринять соответствующие действия в защиту своих интересов позднее, а также не
							означает отказа IMPULS200а от своих прав в случае совершения в последующем подобных либо сходных нарушений.
						</p>
						<p class="p">
							10.8. Настоящее Соглашение составлено на русском языке и в некоторых случаях может быть предоставлено Пользователю для ознакомления на другом языке. В случае расхождения русскоязычной версии Соглашения и версии Соглашения на
							ином языке, применяются положения русскоязычной версии настоящего Соглашения.
						</p>
					</section>
				</div>

	        </Div>
	      </Accordion>
	    </Group>

		{props.Footer}

		{snackbar}
	</Panel>
)};