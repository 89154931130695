import React, { useState, useEffect } from "react";
import { Panel, PanelHeader, Footer, Spacing, Link, Placeholder, ScreenSpinner, Card, Gradient, FormItem, MiniInfoCell, Title, Button, SubnavigationBar, SubnavigationButton, Counter, Div} from '@vkontakte/vkui';
import { useRouteNavigator, useParams } from '@vkontakte/vk-mini-apps-router';
import { Icon56SquareStackUpOutline, Icon20FavoriteOutline, Icon20LocationMapOutline, Icon24Filter } from '@vkontakte/icons';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import axios from 'axios';

export function App(props) {

const routeNavigator = useRouteNavigator();
const params = useParams();

const [data, setData] = useState([]);

useEffect(() => {
	Init();
}, []);

function Init() {
	props.popout(<ScreenSpinner/>);
	var formData = new FormData();
	formData.append('token', localStorage.getItem('STUA'));
	formData.append('FingerUID', props.fingerUID);
	axios.post('https://impuls200.ru/views/ajax/api/frontoffice/projects/Get.php', formData).then((REQ) => {
	    if (REQ.status === 200) {
				setData(REQ.data);
	    }
    props.popout();
  });
};

const styles = {
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  textAlign: 'center',
  height: 350
};

return (
	<Panel id={props.id}>
		<PanelHeader after={props.PanelHeaderAfterProfile}>Проекты</PanelHeader>
		{data?.data?.items?.length > 0 ? (
			<>
			<SubnavigationBar>
				<SubnavigationButton before={<Icon24Filter />} expandable after={<Counter size="s">2</Counter>}>
                  Фильтры
                </SubnavigationButton>
                <SubnavigationButton>Детские</SubnavigationButton>
                <SubnavigationButton>Мужские</SubnavigationButton>
                <SubnavigationButton>Женские</SubnavigationButton>
                <SubnavigationButton>Семейные</SubnavigationButton>
            </SubnavigationBar>

            <Div>
				<ResponsiveMasonry columnsCountBreakPoints={{376: 1, 767: 2, 1024: 3, 1440: 4}}>
				    <Masonry gutter="10px">
					    {data?.data?.items.map((item, key) => {
					    	return <Card style={{animation: 'BoxAnimation 0.7s forwards'}} key={key} mode="outline-tint">
					    		<Gradient style={styles} mode="tint" to="top" className="GradientUserSortig-Item" >
					    			<Title style={{fontFamily: "GilroyExtraBold", marginBottom: 20, padding: '0 16px'}} level="1" weight="2">{item.title}</Title>
					    			<Button onClick={(e) => routeNavigator.push('/projects/'+item.id+'/')} style={{marginBottom: 21}}>Подробнее</Button>
					    			<div style={{background: 'url('+item.cover+')', width: '100%', height: '100%', zIndex: '-1', backgroundSize: 'cover', backgroundPosition: 'center', position: 'absolute', borderRadius: '11px'}}> </div>
					    		</Gradient>
					    		<FormItem>
									<MiniInfoCell before={<Icon20FavoriteOutline />} textWrap="full">
							          Ожидаются отзывы
							        </MiniInfoCell>
							       	<MiniInfoCell before={<Icon20LocationMapOutline />} textWrap="full">
							          Краснодар, Сочи
							        </MiniInfoCell>
						        </FormItem>
					    	</Card>
					    })}
					</Masonry>
				</ResponsiveMasonry>
			</Div>
			</>
		)
		: (
		 	<Placeholder stretched icon={<Icon56SquareStackUpOutline />} header="Упс, это ещё не готово">Всё впереди, скоро тут будут кнопочки и картинки :)</Placeholder>
		 )}
		{props.Footer}
	</Panel>
)};