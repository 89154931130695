import React, { useState, useEffect, useCallback, Fragment } from "react";
import PropTypes from 'prop-types';

import { ModalPage, ModalPageHeader, PanelHeaderClose, PanelHeaderButton,Separator,Placeholder,Group,FormItem,Button,SimpleCell, SegmentedControl, Textarea} from '@vkontakte/vkui';
import { useRouteNavigator, useMetaParams } from '@vkontakte/vk-mini-apps-router';
import { Icon28CheckCircleFill, Icon28CancelOutline } from '@vkontakte/icons';

import axios from 'axios';

export function Modal(props) {

const [inputFields, setInputFields] = useState([]);
const [isSend, setIsSend] = useState(true);
const [comment, setComment] = useState('');

const routeNavigator = useRouteNavigator();
const params = useMetaParams();

const changeRating = (us, val) => {
	var formData = new FormData();
	formData.append('token', localStorage.getItem('STUA'));
	formData.append('staff', us); 
	formData.append('key', params?.key); 
	formData.append('value', val);
	formData.append('FingerUID', props.fingerUID);
	axios.post('https://impuls200.ru/views/ajax/api/frontoffice/photosets/SetRating.php', formData).then((REQ) => {
    if (REQ.status === 200) {

    }
  });
};

const SaveRating = () => {
	var formData = new FormData();
	formData.append('token', localStorage.getItem('STUA'));
	formData.append('staff', 'comment'); 
	formData.append('key', params?.key);
	formData.append('value', comment);
	formData.append('FingerUID', props.fingerUID);
	axios.post('https://impuls200.ru/views/ajax/api/frontoffice/photosets/SetRating.php', formData).then((REQ) => {
    if (REQ.status === 200) {
			routeNavigator.push('/photosets');
    }
  });

};

return (
  <ModalPage onClosed={() => routeNavigator.push('/photosets')} hideCloseButton={true} header={<ModalPageHeader after={<Fragment><PanelHeaderButton onClick={() => routeNavigator.push('/photosets')}><Icon28CancelOutline /></PanelHeaderButton></Fragment>}>Почти готово</ModalPageHeader>} id={props.id}>
  		<Separator />

  		<Placeholder icon={<Icon28CheckCircleFill width={56} height={56} />} header="Выбор сохранён!">
				Выбранные фото отправлены на обработку, в ближайшее время с Вами свяжется менеджер в мессенджере WhatsApp.
			</Placeholder>

			<Separator />

			<Group>

				<SimpleCell extraSubtitle="Выбранные Вами оценки увидит только администрация сайта, в случае необходимости будут приняты необходимые меры." disabled multiline>
          Помогите нам стать лучше!
        </SimpleCell>

				<FormItem top={"Оценьте работу фотографа от 1 до 5"}>
	        <SegmentedControl
	          onChange={(value) => changeRating('photographer', value)}
	          defaultValue="none"
	          options={[
	            {
	              label: '1',
	              value: 1,
	            },
	            {
	              label: '2',
	              value: 2,
	            },
	            {
	              label: '3',
	              value: 3,
	            },
	            {
	              label: '4',
	              value: 4,
	            },
	            {
	              label: '5',
	              value: 5,
	            },
	          ]}
	        />
	      </FormItem>
	      <FormItem top={"Оценьте работу менеджера от 1 до 5"}>
	        <SegmentedControl
	          onChange={(value) => changeRating('manager', value)}
	          defaultValue="none"
	          options={[
	            {
	              label: '1',
	              value: 1,
	            },
	            {
	              label: '2',
	              value: 2,
	            },
	            {
	              label: '3',
	              value: 3,
	            },
	            {
	              label: '4',
	              value: 4,
	            },
	            {
	              label: '5',
	              value: 5,
	            },
	          ]}
	        />
	      </FormItem>
	      <FormItem top={"Оценьте удобство использования сайта IMPULS200 от 1 до 5"}>
	        <SegmentedControl
	          onChange={(value) => changeRating('site', value)}
	          defaultValue="none"
	          options={[
	            {
	              label: '1',
	              value: 1,
	            },
	            {
	              label: '2',
	              value: 2,
	            },
	            {
	              label: '3',
	              value: 3,
	            },
	            {
	              label: '4',
	              value: 4,
	            },
	            {
	              label: '5',
	              value: 5,
	            },
	          ]}
	        />
	      </FormItem>
	      <FormItem>
        	<Textarea onChange={(e) => setComment(e.target.value)} placeholder="Если хотите поблагодарить, или поругать Вы можете оставить тут свой комментарий" />
      	</FormItem>
	      <FormItem>
          <Button onClick={() => SaveRating()} size="l" stretched>
          	Сохранить
          </Button>
        </FormItem>
			</Group>
  </ModalPage>
)};