import React, { useState, useEffect, useCallback, Fragment } from "react";
import PropTypes from 'prop-types';

import { ModalPage, ModalPageHeader, PanelHeaderClose, PanelHeaderButton, Group, Button, FormItem, Counter, Input, Checkbox, Caption, UsersStack, SimpleCell,Spacing,Separator,Link, RichCell,Switch } from '@vkontakte/vkui';
import { useRouteNavigator, useMetaParams } from '@vkontakte/vk-mini-apps-router';
import { Icon28CancelOutline } from '@vkontakte/icons';

import axios from 'axios';

export function Modal(props) {

const [inputFields, setInputFields] = useState([]);
const [isSend, setIsSend] = useState(true);
const [isLoading, setIsLoading] = useState(false);
const [usingPhotos, setUsingPhotos] = useState(false);

const routeNavigator = useRouteNavigator();
const params = useMetaParams();

const handleFormChange = (type, event) => {
	switch (type) {
	  case 'text':
	  	setInputFields({...inputFields, [event.target.name]: event.target.value});
	    break;
	  case 'checkbox':
	  	setInputFields({...inputFields, [event.target.name]: event.target.checked});
	    break;
	}
}

useEffect(() => {
	if (inputFields.is_rules_1 === true && inputFields.is_rules_2 === true && inputFields.is_rules_3 === true) {
		setIsSend(false);
	} else {
		setIsSend(true);
	}
}, [inputFields]);


const sendForm = () => {
	setIsLoading(true);
	var formData = new FormData();
	formData.append('token', localStorage.getItem('STUA'));
	formData.append('uid', params.key);
	formData.append('using_photos', +usingPhotos); 
	formData.append('images', params.images); 
	formData.append('FingerUID', props.fingerUID);
	axios.post('https://impuls200.ru/views/ajax/api/frontoffice/photosets/SaveResult.php', formData).then((REQ) => {
    if (REQ.status === 200) {
    	routeNavigator.replace('/'+params?.key+'/success/', {state: {key: params?.key}})
    	setIsLoading(false);
    }
  });
};

return (
  <ModalPage hideCloseButton={true} header={<ModalPageHeader after={<Fragment><PanelHeaderButton onClick={() => routeNavigator.back()}><Icon28CancelOutline /></PanelHeaderButton></Fragment>}>Сохранение выборки</ModalPageHeader>} id={props.id}>
  		<Separator />
			<Group>

				<SimpleCell disabled expandable="auto" indicator={<Counter>{params?.images?.length}</Counter>}>
          Выбрано фото
        </SimpleCell>

        <SimpleCell disabled expandable="auto" multiline indicator={<Counter>{params?.availableSelection}</Counter>}>
          Бонусные фото (бесплатные/оплаченные)
        </SimpleCell>

        <SimpleCell onChange={(e) => setUsingPhotos(event.target.checked)} extraSubtitle="Будем признательны если разрешите использовать выбранные фото (по согласованию) для портфолио Вашего фотографа" multiline Component="label" after={<Switch />}>Разрешаю использование выбранных фото для портфолио фотографа</SimpleCell>

        <RichCell disabled after={(params?.additionalPayment) + '₽'} bottom={
          <UsersStack visibleCount={6} photos={params?.images?.map((name) => {return 'https://s3.impuls200.ru/media/' + params.key + '/' + name.replace(/\.[^.]+$/, '.webp')})}></UsersStack>
        }>
	        Доплата за выбранные фото
	      </RichCell>

         <Spacing size={16}><Separator /></Spacing>
				<FormItem top="Немного формальностей">
					<Checkbox name="is_rules_1" onChange={event => handleFormChange('checkbox', event)}>
						<span style={{color: 'var(--vkui--color_text_subhead)'}}>
							<Caption level="3">Я проверил(а) выбранные мною фото и не имею претензий и/или возражений к агрегатору услуг IMPULS200 (плательщик НПД Хлебников Руслан Олегович) по количеству и/или качеству выбранных мною фото и/или автоматически подсчитаной суммы для последующей оплаты услуг партнёров и/ли агентов.</Caption>
						</span>
					</Checkbox>
        	<Checkbox name="is_rules_2" onChange={event => handleFormChange('checkbox', event)}>
        		<span style={{color: 'var(--vkui--color_text_subhead)', fontSize: '11px'}}>
        			<Caption level="3">Я подтверждаю что не имею претензий и/или возражений к работе партнёров и/или агентов агрегатора улуг к плательщику НПД Хлебникову Руслану Олеговичу (далее - IMPULS200) а так же к качеству и/или сроку выполнения работ партнёров и/или агентов агрегатора IMPULS200.</Caption>
        		</span>
        		</Checkbox>
        	<Checkbox name="is_rules_3" onChange={event => handleFormChange('checkbox', event)}>
        		<span style={{color: 'var(--vkui--color_text_subhead)', fontSize: '11px'}}>
        			<Caption level="3">Я ознакомил(ся/лась) и соглас(ен/сна) с <Link target="_blank" onClick={(e) => routeNavigator.push('/legal')}>правовыми документами</Link> регламентирующие работу и/или использование агрегатора услуг IMPULS200 (плательщик НПД Хлебников Руслан Олегович)</Caption>
        		</span>
        	</Checkbox>
        </FormItem>
				<FormItem>
          <Button loading={isLoading} disabled={isSend} onClick={() => sendForm()} size="l" stretched>
          	Подтвердить
          </Button>
        </FormItem>
      </Group>
  </ModalPage>
)};