import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { ModalPage, ModalPageHeader, Gradient, Avatar, Title, Group, SimpleCell, CellButton, Header, MiniInfoCell } from '@vkontakte/vkui';
import { Icon24Add, Icon28DoorArrowRightOutline, Icon20PhoneOutline, Icon20CommunityName} from '@vkontakte/icons';
//import axios from 'axios';

export function Modal(props) {

const styles = {
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: 32,
  background: 'url(https://vktech.production.vklanding.ru/img/machine.webp)',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  borderRadius: '9px',
};

return (
  <ModalPage id={props.id}>
		<Gradient mode="tint" to='top' style={styles}>
      <Avatar size={96} src={props.userData.ava_url?.length > 0 ? props.userData.ava_url : undefined} initials={props.userData.name[0]+props.userData.surname[0]} gradientColor="orange">
      	<Avatar.BadgeWithPreset preset="online" />
      </Avatar>
      <Title style={{ marginBottom: 8, marginTop: 20 }} level="2" weight="2">
        {props.userData.name}
      </Title>
    </Gradient>
    <Group mode="plain">
      <MiniInfoCell before={<Icon20CommunityName />} textWrap="full">{props.userData.name}</MiniInfoCell>
      <MiniInfoCell before={<Icon20PhoneOutline />} textWrap="full">{props.userData.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}</MiniInfoCell>
      <CellButton onClick={() => {props.logout();}} before={<Icon28DoorArrowRightOutline />} mode="danger">
        Выйти
      </CellButton>
    </Group>
  </ModalPage>
)};