import React, { useState, useEffect, useCallback, createRef } from "react";
import { useRouteNavigator } from '@vkontakte/vk-mini-apps-router';

import { Panel, PanelHeader, Header, Footer, ScreenSpinner, CardGrid, Card, ContentCard, Button, Banner, Placeholder, Gradient, Spacing, useAdaptivityConditionalRender, RichCell, Avatar, Link, SimpleCell, FormItem, Div, Separator, ButtonGroup, Counter  } from '@vkontakte/vkui';
import { Icon20MessagesOutline, Icon24FavoriteOutline, Icon56GhostOutline } from '@vkontakte/icons';
import { unstable_RichTooltip as RichTooltip } from '@vkontakte/vkui';

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

import axios from 'axios';

export function App(props) {

const routeNavigator = useRouteNavigator();
const [snackbar, setSnackbar] = useState(null);
const [sorting, setSorting] = useState([]);

const { viewWidth } = useAdaptivityConditionalRender();

useEffect(() => {
	Init();
}, []);

function Init() {
	props.popout(<ScreenSpinner/>);
	var formData = new FormData();
	formData.append('token', localStorage.getItem('STUA'));
	formData.append('FingerUID', props.fingerUID);
	axios.post('https://impuls200.ru/views/ajax/api/frontoffice/photosets/Get.php', formData).then((REQ) => {
    if (REQ.status === 200) {
			setSorting(REQ.data);
    }
    props.popout();
  });
};

function getStatus(object) {
	if (object.count === 0) {
		return 'Ожидается выбор фото';
	} else if(object.is_paid === 1 && object.finished_photos_link.length > 7){
		return 'Готовые фото уже можно скачать';
	} else if(object.is_paid === 0){
		return 'Ожидается оплата';
	} else if(object.is_paid === 1 && object.finished_photos_link.length < 7){
		return 'Обработка результата...';
	} 
};

const CopyText = (text) => {
	navigator.clipboard.writeText(text);
  if (snackbar) return;
  setSnackbar(
    <Snackbar
    	subtitle={text}
      onClose={() => setSnackbar(null)}
      before={
        <Avatar size={24} style={{ background: 'var(--vkui--color_background_accent)' }}>
          <Icon16Done fill="#fff" width={14} height={14} />
        </Avatar>
      }
    >
      Ссылка скопирована
    </Snackbar>,
  );
};

return (
	<Panel id={props.id}>
		<PanelHeader after={props.PanelHeaderAfterProfile}>Мои фотосеты</PanelHeader>
			{sorting?.items?.length > 0 ?
				<Div>
					{viewWidth.tabletMinus && <Banner className={viewWidth.tabletMinus.className} style={{padding: 'unset'}} mode="image" header="Порадуйте своих друзей приятным подарком!" subheader="За каждого друга, кто примет участие в бесплатной фотосессии, начислим Вам бонусы для оплаты съёмок от нашей команды." background={
		          <div
		            style={{
		              backgroundColor: '#65c063',
		              backgroundImage:
		                'url(https://s3.impuls200.ru/static/aArX55153Zr5of-J1aLK.png)',
		              backgroundPosition: 'right bottom',
		              backgroundSize: 320,
		              backgroundRepeat: 'no-repeat',
		            }}
		          />
		        }
		        actions={<Link target="_blank" href="https://app.impuls200.ru/GiftFriend"><Button appearance="overlay">Подробнее</Button></Link>}
		      />}
					<ResponsiveMasonry columnsCountBreakPoints={{376: 1, 767: 2, 1024: 3, 1440: 4}}>
			      <Masonry gutter="10px">
							{sorting?.items?.map((item, key) => {
								return <Card style={{animation: 'BoxAnimation 0.7s forwards'}} key={key} mode="outline-tint">
								<Gradient onClick={(e) => {item.is_paid === 0 ? routeNavigator.push('/'+item.key_ps+'/select') : undefined}} mode="tint" to='top' className="GradientUserSortig-Item" style={{background: 'url('+item.cover+')'}}>
									<Gradient style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
										<div style={{padding: '8px', width: 'fit-content'}} ><Counter mode="contrast">{getStatus(item)}</Counter></div>
										<FormItem>
											<RichCell disabled after={<Link target="_blank" href={'whatsapp://send?phone=' + item.manager_info.phone}><RichCell.Icon aria-hidden><Icon20MessagesOutline /></RichCell.Icon></Link>} before={<Avatar size={38} initials="dd" gradientColor="violet" />} caption={"Менеджер" + ' · ' + item.manager_info.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}>
							          {item.manager_info.name}
							        </RichCell>
							        <RichCell disabled after={<Link target="_blank" href={'whatsapp://send?phone=' + item.photographer_info.phone}><RichCell.Icon aria-hidden><Icon20MessagesOutline /></RichCell.Icon></Link>} before={<Avatar size={38} initials="dd" gradientColor="green" />} caption={"Фотограф" + ' · ' + item.photographer_info.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}>
							          {item.photographer_info.name}
							        </RichCell>
								    </FormItem>
							    </Gradient>
					      </Gradient>
					       <Separator />
				        <RichCell caption={'Скидка ' + item.discount + ' ₽'} disabled after={item.add_payment + ' ₽'}>
				          Сумма за {item.count} фото
				        </RichCell>
					      <FormItem>
				          	<ButtonGroup stretched="true">
											<Button disabled={item.is_paid} size="m" stretched onClick={(e) => routeNavigator.push('/'+item.key_ps+'/select')}>Выбрать фото</Button>
											{item.is_paid === 1 && <Link target="_blank" href={item.finished_photos_link}><Button size="m" appearance="neutral" stretched disabled={item.finished_photos_link.length < 7 ? true : false}  mode="outline">Готовые фото</Button></Link>}
											{item.finished_photos_link.length > 7 &&<Button size="m" appearance="neutral" mode="tertiary" before={<Icon24FavoriteOutline fill="#ffa60f"/>}></Button>}
										</ButtonGroup>
								</FormItem>
							</Card>
							})}
						</Masonry>
			    </ResponsiveMasonry>
		    </Div> :
		    <Placeholder stretched icon={<Icon56GhostOutline />} header="Кажется тут пусто">
		      Надеемся, что скоро тут появятся ваши фотосеты
		    </Placeholder>
	  	}

			{props.Footer}

		{snackbar}
	</Panel>
)};