import React, { useState, useEffect } from "react";
import { Panel, PanelHeader, Footer, Spacing, Link, Placeholder } from '@vkontakte/vkui';
import { useRouteNavigator, useParams } from '@vkontakte/vk-mini-apps-router';
import { Icon28SoundWaveOutline } from '@vkontakte/icons';

export function App(props) {

const routeNavigator = useRouteNavigator();

useEffect(() => {
	Init();
}, []);

function Init() {

};

return (
	<Panel id={props.id}>
		<PanelHeader after={props.PanelHeaderAfterProfile}>Мой поток</PanelHeader>
    	<Placeholder stretched icon={<Icon28SoundWaveOutline width={56} height={56}/>} header="Упс, это ещё не готово">Всё впереди, скоро тут будут кнопочки и картинки :)</Placeholder>
		{props.Footer}
	</Panel>
)};