import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { RouterProvider, RouteWithRoot, createBrowserRouter } from '@vkontakte/vk-mini-apps-router';
import { AdaptivityProvider, AppRoot, ConfigProvider } from '@vkontakte/vkui';
import App from "./App";

const routes: RouteWithRoot[] = [
  {
    path: '/',            // Путь
    panel: 'Photosets_PanelHome',  // Желаемый Panel
    view: 'home_view', // Желаемый View
    root: 'home', // Желаемый Root
  },
  {
    path: '/photosets',
    panel: 'Photosets_PanelHome',
    view: 'home_view',
    root: 'home',
  },
  {
    path: '/bind/:key_bind',
    panel: 'loading',
    view: 'bind_view',
    root: 'bind',
  },
  {
    path: '/:key/select/',
    panel: 'Photosets_PanelSelect',
    view: 'home_view',
    root: 'home',
  },
  {
    path: '/:key/confirmation/',
    panel: 'Photosets_PanelSelect',
    view: 'home_view',
    root: 'home',
    modal: 'Photoset_Modal_Confirmation'
  },
  {
    path: '/:key/success/',
    panel: 'Photosets_PanelSelect',
    view: 'home_view',
    root: 'home',
    modal: 'Photoset_Modal_Success'
  },
  {
    path: '/legal',
    panel: 'Legal_PanelHome',
    view: 'home_view',
    root: 'home',
  },
  {
    path: '/projects',
    panel: 'Projects_PanelHome',
    view: 'home_view',
    root: 'home',
  },
  {
    path: '/projects/:key/',
    panel: 'Projects_ViewHome',
    view: 'home_view',
    root: 'home',
  },
  {
    path: '/profile',
    panel: 'Profile_PanelHome',
    view: 'home_view',
    root: 'home',
  },
  {
    path: '/flow',
    panel: 'Flow_PanelHome',
    view: 'home_view',
    root: 'home',
  },
  {
    path: '/login',
    panel: 'panel_login',
    view: 'login_view',
    root: 'login',
  }
];

const router = createBrowserRouter(routes);

const Index = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') !== null ? localStorage.getItem('theme') : 'dark');
  const [platform, setPlatform] = useState('vkcom');

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <ConfigProvider transitionMotionEnabled={true} hasCustomPanelHeaderAfter={false} platform={platform} appearance={theme}>
      <AdaptivityProvider>
        <AppRoot>
          <RouterProvider useBridge={false} notFoundRedirectPath="/404" router={router}>
            <App theme={theme} setPlatform={setPlatform} setTheme={setTheme} />
          </RouterProvider>
        </AppRoot>
      </AdaptivityProvider>
    </ConfigProvider>
  );
}

ReactDOM.render(<Index />, document.getElementById('root'));

if (process.env.NODE_ENV === "development") {
  import("./eruda").then(({ default: eruda }) => {}); //runtime download
}
